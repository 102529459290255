
  export default {
    props: {
      bgColor: {
        type : String,
        default: ''
      },
      customHeight: {
        type : String,
        default: ''
      },
      customJustify: {
        type: String,
        default: ''
      },
      customAlign: {
        type: String,
        default: 'center'
      },
      customDirection: {
        type: String,
        default: ''
      },
      customPadding: {
        type: String,
        default: ''
      },
      img: {
        type: Boolean,
        default: false
      },
      desc: {
        type: Boolean,
        default: false
      },
      btn: {
          type: Boolean,
          default: false
        },
      },
      mw700: {
        type: Boolean,
        default: true
      }
  }
